import useDraw from "@/utils/useDraw"; // * 适配处理

const {
  appRef,
  calcRate,
  windowDraw,
  unWindowDraw
} = useDraw();
import { defineComponent, reactive, toRefs, onBeforeMount, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  components: {},
  name: "",

  setup() {
    const data = reactive({
      navShow: false
    });
    onBeforeMount(() => {//2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
      windowDraw();
      calcRate();
    });
    onUnmounted(() => {
      unWindowDraw();
    });
    let router = useRouter();
    let route = useRoute(); // 返回事件

    return { ...toRefs(data),
      appRef,
      route
    };
  }

});